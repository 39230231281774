import { useCallback, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link, Stack, Typography } from '@mui/material';
import { Edit, FileCopy, Visibility, MailOutline, Wysiwyg, Folder } from '@mui/icons-material';
import {
    Content,
    ContentTypeForComparison,
    LandingPage,
    Template,
    isTemplate as isTemplateTypeGuard,
} from 'domains/content/types';
import IconButton from 'domains/core/components/IconButton';
import Table, { ColumnOption, renderDate } from 'domains/core/components/Table';
import { MutationStatus } from 'models/enums';
import useMutationStatus from 'hooks/useMutationStatus';
import useDeleteLandingPage from 'hooks/mutations/useDeleteLandingPage';
import useDeleteTemplate from 'hooks/mutations/useDeleteTemplate';
import MutationKeys from 'hooks/mutations/keys';
import ConfirmationDialog from 'domains/core/components/ConfirmationDialog';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import InfoSnackbar from 'domains/core/components/Snackbars/InfoSnackbar';
import useContentQueryManager from 'hooks/useContentQueryParams';
import { extractContentIdFromRow, getContentCreatePath } from './utils';
import useGetMoreOptionsCell from './useGetMoreOptionsCell';

export type Props<ContentType> = {
    contentQuery: UseQueryResult<ContentType[], unknown>;
};

const getTitleAndIcon = (isLayout: boolean, isTemplate: boolean) => {
    if (isLayout) {
        return {
            title: 'View template',
            Icon: Visibility,
        };
    } else {
        return {
            title: `Edit ${isTemplate ? 'template' : 'landing page'}`,
            Icon: Edit,
        };
    }
};

const ContentTable = <ContentType extends Template | LandingPage | Content>({
    contentQuery: { data: queryData, isLoading: isQueryLoading, isRefetching, refetch },
}: Props<ContentType>) => {
    const isTemplate = !!queryData && isTemplateTypeGuard(queryData[0]);

    const { contentDelete: hasContentDeleteFeature, contentFolder: hasContentFoldersFeature } = useFlags();

    const history = useHistory();

    const { changeViewParams } = useContentQueryManager();

    const [errorMessage, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [content, setContent] = useState<LandingPage | Template>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const deleteLandingPageStatus = useMutationStatus(MutationKeys.DELETE_LANDING_PAGE);
    const deleteTemplateStatus = useMutationStatus(MutationKeys.DELETE_TEMPLATE);

    const onError = (error: Error) => setError(error.message);
    const onSuccess = () => {
        setSuccessMessage(`"${content.name}" successfully deleted.`);
        refetch();
    };

    const { isLoading: isDeleteLandingPageLoading, mutate: deleteLandingPage } = useDeleteLandingPage(
        `${content?.id}`,
        {
            onError,
            onSuccess,
        }
    );
    const { isLoading: isDeleteTemplateLoading, mutate: deleteTemplate } = useDeleteTemplate(`${content?.id}`, {
        onError,
        onSuccess,
    });

    const isError = deleteTemplateStatus === MutationStatus.ERROR || deleteLandingPageStatus === MutationStatus.ERROR;
    const isLoading = isDeleteLandingPageLoading || isDeleteTemplateLoading;
    const isSuccess =
        deleteTemplateStatus === MutationStatus.SUCCESS || deleteLandingPageStatus === MutationStatus.SUCCESS;

    const infoMessage = `Deleting "${content?.name}"`;

    const toggleDeleteModalOpen = useCallback(
        (content?: Template | LandingPage, isConfirm?: boolean) => {
            const deleteContent = () => (isTemplate ? deleteTemplate() : deleteLandingPage());

            isConfirm ? deleteContent() : setContent(content);
            setIsDeleteModalOpen(!!content);
        },
        [deleteLandingPage, deleteTemplate, isTemplate]
    );

    /**
     * If the hasContentFoldersFeature is true, then we prepend the id with the type since
     * we can have a template, landing page, and folder all with the same id.
     */
    const data: object[] = useMemo(
        () =>
            queryData?.map((content: ContentType) => ({
                ...content,
                dateCreated: new Date(content.createdTimestamp),
                dateUpdated: new Date(content.lastModifiedTimestamp),
                id: hasContentFoldersFeature ? `${content.type}:${content.id}` : content.id,
            })),
        [queryData, hasContentFoldersFeature]
    );

    const renderContentIcon = (val: any) => {
        if (val.type === ContentTypeForComparison.EMAIL) return <MailOutline color="info" />;
        if (val.type === ContentTypeForComparison.LANDING_PAGE) return <Wysiwyg />;
        if (val.type === ContentTypeForComparison.FOLDER) return <Folder color="primary" />;
    };

    const columns: ColumnOption[] = useMemo(() => {
        const renderNameCell = (val: any) => {
            const name = `${val.row.original.isLayout ? 'TEMPLATE - ' : ''}${val.cell.value}`;
            if (hasContentFoldersFeature) {
                return (
                    // TODO: figure out if we actually want that orange color for landing pages
                    <Stack alignItems="center" direction="row" spacing={2} color="warning.dark">
                        {renderContentIcon(val.row.original)}
                        <Typography>{name}</Typography>
                    </Stack>
                );
            }

            return <Link href={getContentCreatePath(isTemplate, val.row.original.id)}>{name}</Link>;
        };

        const nameColumn: ColumnOption = {
            Header: 'Name',
            accessor: 'name',
            sortType: 'string',
            align: 'left',
            Cell: (val: any) => renderNameCell(val),
        };
        const dateCreatedColumn: ColumnOption = {
            Header: 'Date Created',
            accessor: 'dateCreated',
            sortType: 'datetime',
            Cell: renderDate,
        };
        const dateUpdatedColumn: ColumnOption = {
            Header: 'Date Updated',
            accessor: 'dateUpdated',
            sortType: 'datetime',
            Cell: renderDate,
        };
        const typeColumn: ColumnOption = {
            Header: 'Type',
            accessor: 'type',
            Cell: (val: any) => {
                if (hasContentFoldersFeature) return val.cell.value;
                return isTemplate ? 'email' : 'landing page';
            },
        };

        const copyColumn: ColumnOption = {
            Header: '',
            accessor: 'copy',
            Cell: (val: any) => (
                <IconButton
                    title={`Copy ${isTemplate ? 'template' : 'landing page'}`}
                    handleClick={() => {
                        const path = getContentCreatePath(isTemplate, val.row.values.id, true);
                        history.push(path);
                    }}
                    Icon={FileCopy}
                />
            ),
            disableSortBy: true,
            align: 'right',
        };

        const editColumn: ColumnOption = {
            Header: '',
            accessor: 'id',
            Cell: (val: any) => (
                <IconButton
                    handleClick={() => {
                        const path = getContentCreatePath(isTemplate, val.row.values.id);
                        history.push(path);
                    }}
                    {...getTitleAndIcon(val.row.original.isLayout, isTemplate)}
                />
            ),
            disableSortBy: true,
            align: 'right',
        };

        const moreOptionsColumn: ColumnOption = {
            Header: '',
            accessor: 'id',
            align: 'right',
            disableSortBy: true,
            Cell: (val: any) => useGetMoreOptionsCell({ isTemplate, toggleDeleteModalOpen, val }),
        };

        if (hasContentDeleteFeature) {
            return [nameColumn, dateCreatedColumn, dateUpdatedColumn, typeColumn, moreOptionsColumn];
        }

        if (hasContentFoldersFeature) {
            return [nameColumn, typeColumn, dateCreatedColumn, dateUpdatedColumn, moreOptionsColumn];
        }

        return [nameColumn, dateCreatedColumn, dateUpdatedColumn, typeColumn, copyColumn, editColumn];
    }, [hasContentFoldersFeature, hasContentDeleteFeature, isTemplate, history, toggleDeleteModalOpen]);

    // TODO: update to use real row type here
    const onRowClick = (row: any) => {
        const id = extractContentIdFromRow(row);
        if (row.original.type === ContentTypeForComparison.FOLDER) {
            changeViewParams({ newFolderId: id });
        } else {
            const path = getContentCreatePath(row.original.type === ContentTypeForComparison.EMAIL, id);
            history.push(path);
        }
    };

    return (
        <>
            {isError && <ErrorSnackbar errorMessage={errorMessage} />}
            {isLoading && <InfoSnackbar successMessage={infoMessage} />}
            {isSuccess && <SuccessSnackbar successMessage={successMessage} />}
            <ConfirmationDialog
                dialogProps={{
                    confirmButtonText: 'Delete',
                    contentText: `You are about to delete "${content?.name}." This action cannot be undone.`,
                    title: `Delete ${
                        isTemplate ? ContentTypeForComparison.EMAIL : ContentTypeForComparison.LANDING_PAGE
                    }`,
                }}
                open={isDeleteModalOpen}
                onConfirm={() => toggleDeleteModalOpen(null, true)}
                onModalClose={() => toggleDeleteModalOpen()}
            />
            {!isQueryLoading && (
                <Table
                    data={data}
                    columns={columns}
                    defaultSortingRules={[{ id: 'dateUpdated', desc: true }]}
                    isRefetching={isRefetching}
                    {...(hasContentFoldersFeature && { onRowClick })}
                    refetch={refetch}
                />
            )}
        </>
    );
};

export default ContentTable;
