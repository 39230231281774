import { useState, useEffect, useMemo, useCallback } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { SupervisedUserCircleOutlined } from '@mui/icons-material/';
import { Box, Typography, Stack, Tooltip } from '@mui/material';
import { FilterCategory, RecordType, URLPaths } from 'models/enums';
import useSegmentPreviewCount from 'hooks/mutations/useSegmentPreviewCount';
import useCreateSegment, { SegmentPostBody } from 'hooks/mutations/useCreateSegment';
import QueryKeys from 'hooks/queries/keys';
import useSegmentFilters from 'hooks/queries/useSegmentFilters';
import useSends from 'hooks/queries/useSends';
import Button from 'domains/core/components/Button';
import PageLayout from 'domains/core/components/PageLayout';
import ErrorSnackbar from 'domains/core/components/Snackbars/ErrorSnackbar';
import TextField from 'domains/core/components/TextField';
import { SegmentDefinition, SegmentFilterCategorySpecificField, SegmentFilterState } from 'domains/segments/types';
import Filter from './Filter';
import PreviewCount from './PreviewCount';
import {
    getDemographicFilters,
    getSegmentDefinition,
    getEventFilter,
    getDemographicFilter,
    getEngagementFilter,
} from 'domains/segments/utils';
import { isSegmentFiltersValid } from './utils';
import theme from 'theme';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AutocompleteInput from './Filter/FilterValueInput/AutocompleteInput';
import { Value, getOptionValues } from 'domains/core/components/Select/Select';
import { getFriendlyName } from 'utils';

type DateTimeErrors = { [key: string]: boolean };

type StringOption = Omit<Value, 'label'> & {
    label: string;
};

type SegmentCreateFormInputs = {
    filters: SegmentFilterState<SegmentFilterCategorySpecificField>[];
    matchType: string;
    recordTypes: StringOption[];
    segmentName: string;
};

const allContactsRecordType = {
    label: RecordType.ALL_CONTACTS,
    value: RecordType.ALL_CONTACTS,
};
const autocompleteInputSX = {
    sxAutocomplete: { width: '24rem' },
    sxChip: { textTransform: 'capitalize' },
    sxOption: { textTransform: 'capitalize' },
};

const getHasAllContacts = (recordTypeOptions: StringOption[]) =>
    recordTypeOptions.some(({ value }) => value === RecordType.ALL_CONTACTS);
const removeOtherOptions = (recordTypeOptions: StringOption[]) =>
    recordTypeOptions.filter(({ value }) => value === RecordType.ALL_CONTACTS);
const removeAllContactsOption = (recordTypeOptions: StringOption[]) =>
    recordTypeOptions.filter(({ value }) => value !== RecordType.ALL_CONTACTS);

/**
 * Creates audiences by segmenting the contacts based on the filters created.
 *
 * @returns The React node created by this component.
 */
const SegmentCreatePage = () => {
    const [category, setCategory] = useState<FilterCategory>();
    const [datetimeErrors, setDatetimeErrors] = useState<DateTimeErrors>({});
    const [isAudiencePreviewed, setIsAudiencePreviewed] = useState(false);
    const [nextFilterId, setNextFilterId] = useState(0);
    const [valuesRef, setValuesRef] = useState('');
    const [recordTypeInputValue, setRecordTypeInputValue] = useState('');

    const { recordTypeSegmentation: hasRecordTypeSegmentation } = useFlags();

    const { data: segmentFilters, isSuccess: isSegmentFiltersSuccess } = useSegmentFilters({
        version: 2,
    });

    const {
        control,
        formState: { isValid },
        getValues,
        setValue,
        watch,
    } = useForm<SegmentCreateFormInputs>({
        mode: 'onTouched',
        defaultValues: {
            filters: [],
            matchType: 'AND',
            recordTypes: [],
            segmentName: '',
        },
    });

    const formData = getValues();
    const { filters: stateFilters, matchType: stateMatchType, segmentName: stateSegmentName } = formData;
    const stateRecordTypeOptions: StringOption[] = formData.recordTypes;
    const isRecordTypesEmpty = stateRecordTypeOptions.length === 0;

    const segmentRecordTypes = segmentFilters?.demographic?.recordTypes;

    const segmentRecordTypeOptions: Value[] = useMemo(
        () => [
            allContactsRecordType,
            ...(segmentRecordTypes?.map(({ recordType }) => ({
                label: recordType,
                value: recordType,
            })) ?? []),
        ],
        [segmentRecordTypes]
    );

    const recordTypes = useMemo(() => getOptionValues(stateRecordTypeOptions), [stateRecordTypeOptions]);

    const hasAllContacts = useMemo(() => isRecordTypesEmpty || getHasAllContacts(stateRecordTypeOptions), [
        isRecordTypesEmpty,
        stateRecordTypeOptions,
    ]);

    const demographicFilters = useMemo(() => {
        if (!segmentFilters) {
            return [];
        }
        const result = getDemographicFilters({ hasAllContacts, recordTypes, segmentRecordTypes });

        return result;
    }, [segmentFilters, hasAllContacts, recordTypes, segmentRecordTypes]);

    const engagementFilters = useMemo(() => {
        if (!segmentFilters) {
            return [];
        }
        // RecordTypes[0] represents engagement filters because there's a single item and its recordType value is "" from the API
        return segmentFilters.engagement.recordTypes[0].filters;
    }, [segmentFilters]);

    const eventRecordTypeFilters = useMemo(() => {
        if (!segmentFilters) {
            return [];
        }
        const titleCasedRecordTypeFilters = segmentFilters.contactEvent.recordTypes?.map(({ recordType, filters }) => ({
            filters,
            recordType: getFriendlyName(recordType),
        }));
        return titleCasedRecordTypeFilters;
    }, [segmentFilters]);

    const engagementNameToValuesRef = useMemo(() => {
        if (!engagementFilters) {
            return {};
        }
        return engagementFilters.reduce((previousValue: Record<string, string>, currentValue) => {
            previousValue[currentValue.name] = currentValue.valuesRef;
            return previousValue;
        }, {});
    }, [engagementFilters]);

    const { data: sends, isSuccess: isSendsSuccess } = useSends({
        path: valuesRef,
        reactQueryOptions: { enabled: Boolean(valuesRef) },
    });

    const {
        mutate: getAudienceCount,
        isError: isAudienceCountError,
        isLoading: isAudienceCountLoading,
        error: audienceCountError,
        data: audienceCount,
    } = useSegmentPreviewCount();

    // Do no call these functions directly, instead call append, etc below that also reset the preview.
    const { fields, append: appendAction, remove: removeAction, update: updateAction } = useFieldArray({
        keyName: 'key',
        name: 'filters',
        control,
    });

    const datetimeError = Object.values(datetimeErrors).includes(true);

    const handleDatetimeError = (filterId: string, error: boolean) =>
        setDatetimeErrors({ ...datetimeErrors, [filterId]: error });

    const history = useHistory();
    const queryClient = useQueryClient();

    const onSuccess = () => {
        queryClient.invalidateQueries({
            // Updating the audiences to get the last segment added
            predicate: (query: any) => {
                const firstQueryKey: string = query.queryKey[0];
                const invalidate = firstQueryKey === QueryKeys.SEGMENTS;
                return invalidate;
            },
        });
        history.push(URLPaths.SEGMENTS);
    };

    const { mutate: saveSegment, error: createSegmentError, isError: isCreateSegmentError } = useCreateSegment({
        onSuccess,
    });

    const updateFilter = (
        index: number,
        filter: SegmentFilterState<SegmentFilterCategorySpecificField>,
        resetPreview: boolean = true
    ) => {
        updateAction(index, filter);
        if (resetPreview) {
            setIsAudiencePreviewed(false);
        }

        if (filter.category === FilterCategory.CONTACT_ACTIVITY && filter.name) {
            setValuesRef(engagementNameToValuesRef[filter.name]);
        }
        if (fields.length === 1) {
            setCategory(undefined);
        } else {
            if (category === undefined) {
                const newCategory = filter.category !== undefined ? filter.category : undefined;
                setCategory(newCategory);
            }
        }
    };

    const removeFilter = (index: number) => {
        removeAction(index);
        setIsAudiencePreviewed(false);

        // Length will be 1 after remove, so category is set to nothing so user can freely choose
        if (fields.length === 2) {
            setCategory(undefined);
        }
        setNextFilterId((id) => id - 1);
    };

    const getSegment = () =>
        getSegmentDefinition({
            filters: stateFilters,
            hasAllContacts,
            matchType: stateMatchType as 'AND' | 'OR',
            recordTypes,
        });

    const previewAudienceCount = () => {
        const segmentDefinition = getSegment();

        getAudienceCount(segmentDefinition);
        setIsAudiencePreviewed(true);
    };

    const saveAudience = async () => {
        const segmentDefinition = getSegment();
        const body: SegmentPostBody = {
            name: stateSegmentName,
            segmentDefinition,
        };

        saveSegment(body);
    };

    const handleAddFilter = useCallback(() => {
        const addFilter = (id: number) => {
            const filterLength = stateFilters?.length - 1;
            const lastFilterCategory = stateFilters[filterLength]?.category;

            const append = (value: Parameters<typeof appendAction>[0]) => {
                appendAction(value);
                setIsAudiencePreviewed(false);
            };

            if (fields.length === 1) {
                setCategory(lastFilterCategory);
            }
            append({
                id,
                category: lastFilterCategory,
                name: '',
                operator: undefined,
                categorySpecificFields: { value: '' },
            });
        };
        addFilter(nextFilterId);
        setNextFilterId((id) => id + 1);
    }, [appendAction, fields.length, nextFilterId, stateFilters]);

    const getNewRecordTypes = (autocompleteOptions: StringOption[]): StringOption[] => {
        const isNewValueAllContacts = getHasAllContacts(autocompleteOptions);
        const isOldValueAllContacts = getHasAllContacts(stateRecordTypeOptions);

        // When other option is selected and "All Contacts" was previously selected, remove "All Contacts".
        // When "All Contacts" is selected and other option was previously selected, remove other options.
        // When other option is selected and "All Contacts" was not previously selected, select other option without additional processing.

        return isNewValueAllContacts
            ? isOldValueAllContacts
                ? removeAllContactsOption(autocompleteOptions)
                : removeOtherOptions(autocompleteOptions)
            : autocompleteOptions;
    };

    const getNewFiltersByRecordTypes = (
        newRecordTypes: StringOption[]
    ): SegmentFilterState<SegmentFilterCategorySpecificField>[] => {
        const recordTypes = getOptionValues(newRecordTypes);
        const demographicFilters = getDemographicFilters({
            hasAllContacts: getHasAllContacts(newRecordTypes),
            recordTypes,
            segmentRecordTypes,
        });

        const filterNameSet = new Set(demographicFilters.map((filter) => filter.name));

        return stateFilters.filter(
            (filter) => filter.category !== FilterCategory.CONTACT_DETAILS || filterNameSet.has(filter.name)
        );
    };

    const changeRecordTypesAndFilters = (autocompleteOptions: StringOption[]) => {
        const newRecordTypes = getNewRecordTypes(autocompleteOptions);

        setValue('recordTypes', newRecordTypes);
        setRecordTypeInputValue('');

        const newFilters = getNewFiltersByRecordTypes(newRecordTypes);

        setValue('filters', newFilters);
        setNextFilterId(newFilters.length);
    };

    // Checks the validity of the page form input on every update of the page
    const location = useLocation();
    const state = location?.state as { segmentDefinition: SegmentDefinition };
    const { demographic, engagement, contactEvent, matchType, contactRecordType } = state?.segmentDefinition || {};

    const isFiltersEmpty = !stateFilters?.length;
    const isSegmentNew = !location.state;

    useEffect(() => {
        // If this is not a copy and FF is enabled, then add 'All Contacts' record type.
        if (isSegmentNew && hasAllContacts && hasRecordTypeSegmentation) {
            setValue('recordTypes', [allContactsRecordType]);
        }
    }, [hasAllContacts, hasRecordTypeSegmentation, isSegmentNew, setValue]);

    useEffect(() => {
        // If this is not a copy, then add a filter.
        if (isSegmentNew && isFiltersEmpty) {
            handleAddFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        /* 
        If there are engagement filters, and the sends have not been loaded, set the valuesRef to trigger the request.
        For now, assume that the valuesRef is the same for each of the engagement filters.
        */
        const hasEngagement = engagement?.length;

        if (hasEngagement && !isSendsSuccess) {
            const firstEngagementName = engagement[0].name;

            setValuesRef(engagementNameToValuesRef[firstEngagementName]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!location.state) return;

        // Set the initial filters on Audience copy
        const formFilters = [
            ...(contactEvent?.map((event, index) => getEventFilter(event, index)) || []),
            ...(demographic?.map((demographic, index) => getDemographicFilter(demographic, index)) || []),
            ...(sends?.length
                ? engagement?.map((engagement, index) => getEngagementFilter(engagement, index, sends))
                : []),
        ];

        const recordTypes: StringOption[] = contactRecordType?.map((value) => ({ label: value, value }));

        setValue('matchType', matchType);
        setValue('filters', formFilters);
        setValue('recordTypes', recordTypes ?? [allContactsRecordType]);

        setNextFilterId(formFilters?.length);
    }, [contactEvent, contactRecordType, demographic, engagement, location.state, matchType, sends, setValue]);

    const hasInvalidFilters = !isSegmentFiltersValid(stateFilters, isRecordTypesEmpty);
    const isMaxFilterLimit = fields.length === 12;
    const isPreviewDisabled = datetimeError || hasInvalidFilters || isAudienceCountLoading;
    const isSaveDisabled =
        datetimeError || hasInvalidFilters || !isValid || (hasRecordTypeSegmentation && isRecordTypesEmpty);

    return (
        <PageLayout
            header={
                <Tooltip
                    title="contacts grouped by a set of filter criteria so you can send targeted messages"
                    aria-label="contacts grouped by a set of filter criteria so you can send targeted messages"
                >
                    <Typography variant="h4">audiences</Typography>
                </Tooltip>
            }
            headerIcon={<SupervisedUserCircleOutlined fontSize="inherit" />}
            headerAction={
                <Stack direction="row" columnGap="1rem">
                    <Button
                        onClick={() => {
                            history.push(URLPaths.SEGMENTS);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isSaveDisabled}
                        endIcon={<SupervisedUserCircleOutlined />}
                        onClick={saveAudience}
                        variant="outlined"
                    >
                        Save
                    </Button>
                </Stack>
            }
        >
            <Stack pb={4}>
                {isCreateSegmentError && <ErrorSnackbar errorMessage={createSegmentError.message} />}
                <Stack direction="row" alignItems="center" columnGap={2}>
                    <Controller
                        name="segmentName"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'This field is required',
                            minLength: { value: 3, message: 'name must have at least 3 characters' },
                            maxLength: { value: 255, message: 'name must be under 255 characters' },
                        }}
                        render={({ field: { onChange, value, onBlur }, fieldState: { error, invalid } }) => (
                            <TextField
                                required
                                label="Audience name"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                error={invalid}
                                helperText={invalid ? error.message : null}
                                width="24rem"
                            />
                        )}
                    />
                    <Tooltip
                        title="Preview the number of current contacts that meet the applied filters"
                        aria-label="preview the number of current contacts that meet the applied filters"
                    >
                        <Box>
                            <Button
                                disabled={isPreviewDisabled}
                                variant="outlined"
                                onClick={previewAudienceCount}
                                size="medium"
                            >
                                Preview
                            </Button>
                        </Box>
                    </Tooltip>
                    <Stack direction="row" justifyContent="flex-start">
                        <PreviewCount
                            isAudiencePreviewed={isAudiencePreviewed}
                            data={audienceCount}
                            error={audienceCountError}
                            isError={isAudienceCountError}
                            loading={isAudienceCountLoading}
                        />
                    </Stack>
                </Stack>
                <Stack mt={8} mb={2}>
                    <Typography variant="h6">create audience</Typography>
                    <Typography variant="body2" marginTop=".5rem">
                        Filter your contacts by specific criteria to create targeted audiences.
                    </Typography>
                </Stack>
                {isSegmentFiltersSuccess && hasRecordTypeSegmentation && (
                    <Box mt={1} mb={3}>
                        <Controller
                            control={control}
                            name="recordTypes"
                            render={({ field: { value }, fieldState: { error, invalid } }) => (
                                <AutocompleteInput
                                    autocompleteValue={value}
                                    autocompleteInputValue={recordTypeInputValue}
                                    error={invalid}
                                    helperText={invalid ? error.message : null}
                                    multiple
                                    onChange={changeRecordTypesAndFilters}
                                    onInputChange={setRecordTypeInputValue}
                                    options={segmentRecordTypeOptions}
                                    placeholderName="Contact Type"
                                    styles={autocompleteInputSX}
                                    type="string"
                                />
                            )}
                            rules={{
                                required: 'Select contact type',
                            }}
                        />
                    </Box>
                )}
                {isSegmentFiltersSuccess && (
                    <Controller
                        control={control}
                        name="matchType"
                        render={({ field: { onChange, value } }) => {
                            const handleChange = (e: any) => {
                                setIsAudiencePreviewed(false);
                                onChange(e.target.value);
                            };
                            return (
                                <>
                                    {watch('recordTypes') &&
                                        stateFilters.map((filter, index) => (
                                            <Filter
                                                key={`${filter.category}-${filter.id}`}
                                                demographicFilters={demographicFilters}
                                                engagementFilters={engagementFilters}
                                                eventRecordTypeFilters={eventRecordTypeFilters}
                                                filter={filter}
                                                index={index}
                                                sends={sends}
                                                value={value}
                                                handleChange={handleChange}
                                                handleDatetimeError={handleDatetimeError}
                                                hideMatchType={index === fields.length - 1}
                                                removeFilter={removeFilter}
                                                updateFilter={updateFilter}
                                            />
                                        ))}
                                </>
                            );
                        }}
                    />
                )}
                <Box mt={4}>
                    <Button
                        disabled={isMaxFilterLimit}
                        variant="contained"
                        onClick={handleAddFilter}
                        size="medium"
                        sx={{
                            background: theme.palette.action.active,
                            '&:hover': {
                                background: theme.palette.action.selected,
                            },
                        }}
                    >
                        Add Filter
                    </Button>
                </Box>
            </Stack>
        </PageLayout>
    );
};

export default SegmentCreatePage;
