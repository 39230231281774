import { URLPaths } from 'models/enums';

export const extractContentIdFromVal = (val: any) => Number(String(val.row.original.id).split(':')[1]);

export const extractContentIdFromRow = (row: any) => Number(String(row.original.id).split(':')[1]);

export const getContentCreatePath = (isEmail: boolean, id: number, isCopy?: boolean) => {
    const basePath = isEmail ? URLPaths.CONTENT_CREATE_EMAIL : URLPaths.CONTENT_CREATE_PAGE;
    const copyQuery = isCopy ? '&isCopy=true' : '';
    return `${basePath}?id=${id}${copyQuery}`;
};
