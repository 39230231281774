import { Grid } from '@mui/material';
import { useEffect } from 'react';
import DateFilter from '../../filtering/DateFilter';
import IncludeUnsubsFilter from '../../filtering/IncludeUnsubsFilter';
import { FilterValuesState } from '../../filtering/types';
import { getIsValidDateRange, getIsValidRelativeTimeframe, getTimeframeForApi } from '../../filtering/utils';
import FiltersWrapper from '../../filtering/FiltersWrapper/FiltersWrapper';
import SendsFilter from './SendsFilter';
import CurationsFilter from './CurationsFilter';
import useGetFilterData from './useGetFilterData';

type Props = {
    filterValues: FilterValuesState;
    handleApplyFilters: () => void;
    isRelativeTimeframe?: boolean;
    setFilterValues: (filters: FilterValuesState) => void;
    setIsRelativeTimeframe?: (isRelativeTimeframe: boolean) => void;
};

const Filters = ({
    filterValues,
    handleApplyFilters,
    isRelativeTimeframe,
    setFilterValues,
    setIsRelativeTimeframe,
}: Props) => {
    const isValidDateRange = getIsValidDateRange(filterValues?.filterTimeframe);
    const isValidRelativeTimeframe = getIsValidRelativeTimeframe(filterValues?.filterRelativeTimeframe);
    const hasInvalidFilters = isRelativeTimeframe ? !isValidRelativeTimeframe : !isValidDateRange;

    const { getData: getFilterData, isError, isLoading, data } = useGetFilterData();

    useEffect(() => {
        if (!hasInvalidFilters) {
            const timeframeForApi = getTimeframeForApi(isRelativeTimeframe, filterValues);
            getFilterData({ timeframe: timeframeForApi });
        }
        // We only want this to run when the timeframes are updated
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues.filterTimeframe, filterValues.filterRelativeTimeframe]);

    return (
        <FiltersWrapper disabled={hasInvalidFilters} handleApplyFilters={handleApplyFilters}>
            <DateFilter
                filterValues={filterValues}
                isRelativeTimeframe={isRelativeTimeframe}
                setFilterValues={setFilterValues}
                setIsRelativeTimeframe={setIsRelativeTimeframe}
            />
            <Grid alignItems="center" container display="flex" mt={0} mb={2} spacing={2}>
                <Grid item xs={12} md={6} lg={5}>
                    <CurationsFilter
                        data={data?.campaigns}
                        disabled={isError || isLoading}
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <SendsFilter
                        data={data?.sends}
                        disabled={isError || isLoading}
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={2} minWidth="220px">
                    <IncludeUnsubsFilter filterValues={filterValues} setFilterValues={setFilterValues} />
                </Grid>
            </Grid>
        </FiltersWrapper>
    );
};

export default Filters;
