import { Stack } from '@mui/material';
import { SegmentFilter, SegmentFilterEventFields, SegmentFilterState } from 'domains/segments/types';
import AddSubFilterButton from 'domains/segments/pages/SegmentCreatePage/Filter/AddSubFilterButton';
import SubFilter from 'domains/segments/pages/SegmentCreatePage/Filter/SubFilters/SubFilter/SubFilter';

const emptySubFilter = {
    name: '',
    operator: null as any,
    value: '',
};

const subFiltersStyle = {
    paddingTop: '16px',
};

type Props = {
    addButtonDataTestId: string;
    filterIndex: number;
    filter: SegmentFilterState<SegmentFilterEventFields>;
    filters: SegmentFilter[];
    handleDatetimeError: (filterId: string, error: boolean) => void;
    isResponsive?: boolean;
    updateSegmentFilter: (
        index: number,
        filter: SegmentFilterState<SegmentFilterEventFields>,
        resetPreview?: boolean
    ) => void;
};

const SubFilters = ({
    addButtonDataTestId,
    filterIndex,
    filter,
    filters,
    handleDatetimeError,
    isResponsive,
    updateSegmentFilter,
}: Props) => {
    const { categorySpecificFields } = filter;
    const { conditions } = categorySpecificFields;

    const hasSubFilters = !!conditions?.length;
    const shouldShowAddSubFilterButton = conditions?.length !== filters.length;

    const addSubFilterButtonStyle = {
        marginLeft: hasSubFilters || isResponsive ? '8px' : 0,
        ...(hasSubFilters && { marginTop: '16px' }),
    };

    const handleClickAddSubFilter = () => {
        const newConditions = hasSubFilters ? [...conditions, emptySubFilter] : [emptySubFilter];

        updateSegmentFilter(filterIndex, {
            ...filter,
            categorySpecificFields: {
                ...categorySpecificFields,
                conditions: newConditions,
            },
        });
    };

    return (
        <Stack sx={subFiltersStyle}>
            {hasSubFilters &&
                conditions.map((condition, index) => (
                    <SubFilter
                        key={`${filterIndex}-${index}`}
                        condition={condition}
                        conditionIndex={index}
                        filterOptions={filters}
                        filterState={filter}
                        filterIndex={filterIndex}
                        handleDatetimeError={handleDatetimeError}
                        isResponsive={isResponsive}
                        updateSegmentFilter={updateSegmentFilter}
                    />
                ))}
            {shouldShowAddSubFilterButton && (
                <Stack sx={addSubFilterButtonStyle}>
                    <AddSubFilterButton dataTestId={addButtonDataTestId} handleClick={handleClickAddSubFilter} />
                </Stack>
            )}
        </Stack>
    );
};

export default SubFilters;
