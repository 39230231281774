import { ContentType } from 'domains/content/types';
import useContentQueryManager from 'hooks/useContentQueryParams';
import useContentWithFolders from 'hooks/queries/useContentWithFolders';
import ContentPageLayout from '../ContentPageLayout';
import { contentTypeToLabel } from '../ContentPageLayout/ContentPageLayout';

const ContentPageWithFolders = () => {
    const contentQueryManager = useContentQueryManager();
    const { changeViewParams, type, folderId } = contentQueryManager;
    const contentQuery = useContentWithFolders({ folderId, type });

    const headerOtherDropdownProps = {
        label: contentTypeToLabel(type),
        options: [
            {
                label: contentTypeToLabel(ContentType.ALL),
                onClick: () => changeViewParams({ newType: ContentType.ALL }),
                selected: type === ContentType.ALL || !type,
            },
            {
                label: contentTypeToLabel(ContentType.FOLDER),
                onClick: () => changeViewParams({ newType: ContentType.FOLDER }),
                selected: type === ContentType.FOLDER,
            },
            {
                label: contentTypeToLabel(ContentType.EMAIL),
                onClick: () => changeViewParams({ newType: ContentType.EMAIL }),
                selected: type === ContentType.EMAIL,
            },
            {
                label: contentTypeToLabel(ContentType.PAGE),
                onClick: () => changeViewParams({ newType: ContentType.PAGE }),
                selected: type === ContentType.PAGE,
            },
        ],
    };

    return <ContentPageLayout contentQuery={contentQuery} headerOtherDropdownProps={headerOtherDropdownProps} />;
};

export default ContentPageWithFolders;
