import { useHistory } from 'react-router';
import { URLPaths } from 'models/enums';
import useTemplates from 'hooks/queries/useTemplates';
import useLandingPages from 'hooks/queries/useLandingPages';
import useQueryParams from 'hooks/useQueryParams';
import { ContentType } from 'domains/content/types';
import ContentPageLayout from '../ContentPageLayout';
import { contentTypeToLabel } from '../ContentPageLayout/ContentPageLayout';

const ContentPageWithoutFolders = () => {
    const history = useHistory();

    const templatesQuery = useTemplates();
    const landingPagesQuery = useLandingPages();
    const hasLandingPages = Boolean(landingPagesQuery?.data?.length);

    const query = useQueryParams();
    const type: ContentType = (query.get('type') as ContentType) ?? ContentType.EMAIL;

    const headerOtherDropdownProps = {
        label: contentTypeToLabel(type),
        options: [
            {
                label: contentTypeToLabel(ContentType.EMAIL),
                onClick: () => history.push(`${URLPaths.CONTENT}?type=email`),
                selected: type === ContentType.EMAIL,
            },
            {
                label: contentTypeToLabel(ContentType.PAGE),
                onClick: () => history.push(`${URLPaths.CONTENT}?type=page`),
                selected: type === ContentType.PAGE,
            },
        ],
    };

    return (
        <ContentPageLayout
            contentQuery={type === ContentType.EMAIL ? templatesQuery : landingPagesQuery}
            headerOtherDropdownProps={hasLandingPages && headerOtherDropdownProps}
        />
    );
};

export default ContentPageWithoutFolders;
