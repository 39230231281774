import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHistory } from 'react-router';
import SuccessSnackbar from 'domains/core/components/Snackbars/SuccessSnackbar';
import MutationKeys from 'hooks/mutations/keys';
import useMutationStatus from 'hooks/useMutationStatus';
import { MutationStatus, URLPaths } from 'models/enums';
import { Content, ContentType, LandingPage, Template } from 'domains/content/types';
import PageLayout from 'domains/core/components/PageLayout';
import ContentIcon from 'domains/core/components/SvgIcons/ContentIcon';
import DropdownButton from 'domains/core/components/DropdownButton';
import EmptyStateLayout from 'domains/core/components/EmptyStateLayout';
import ContentTable from '../ContentTable';
import { Props as ContentTableProps } from '../ContentTable/ContentTable';

export const contentTypeToLabel = (type: ContentType | null): string => {
    if (!type) return ContentType.ALL;
    if (type === ContentType.PAGE) return 'Landing Page';
    return type;
};

export type Props = {
    contentQuery: ContentTableProps<Template | LandingPage | Content>['contentQuery'];
    headerOtherDropdownProps?: {
        label: string;
        options: { label: string; onClick: () => void; selected: boolean }[];
    };
};

const ContentPageLayout = ({ contentQuery, headerOtherDropdownProps }: Props) => {
    const history = useHistory();

    const createLandingPageStatus = useMutationStatus(MutationKeys.CREATE_LANDING_PAGE);
    const createTemplateStatus = useMutationStatus(MutationKeys.CREATE_TEMPLATE);
    const updateLandingPageStatus = useMutationStatus(MutationKeys.UPDATE_LANDING_PAGE);
    const updateTemplateStatus = useMutationStatus(MutationKeys.UPDATE_TEMPLATE);

    const { data, isError, isSuccess } = contentQuery;
    const isEmpty = isSuccess && !data?.length;

    const dropdownOptions = [
        {
            label: 'email',
            onClick: () => history.push(`${URLPaths.CONTENT_CREATE_EMAIL}?id=default`),
        },
        {
            label: 'landing page',
            onClick: () => history.push(`${URLPaths.CONTENT_CREATE_PAGE}?id=default`),
        },
    ];

    const CreateButton = (
        <DropdownButton
            aria-label="Choose content type to create"
            data-testid="content-create-button"
            label="Create"
            variant="outlined"
            options={dropdownOptions}
            icon={<ContentIcon />}
        />
    );

    const renderHeaderOthers = () => {
        if (isEmpty || !headerOtherDropdownProps) return;

        return (
            <DropdownButton
                sx={{ textTransform: 'capitalize' }}
                aria-label="Choose which type of content to view"
                variant="text"
                icon={<KeyboardArrowDownIcon />}
                {...headerOtherDropdownProps}
            />
        );
    };

    const renderPageBody = () => {
        if (isError) {
            return (
                <EmptyStateLayout
                    caption="Please try refreshing the page."
                    header="There was a problem loading the content."
                />
            );
        }
        if (isEmpty) {
            return (
                <EmptyStateLayout
                    caption="You currently do not have any content saved."
                    header="let's design your content"
                >
                    {CreateButton}
                </EmptyStateLayout>
            );
        }
        return <ContentTable contentQuery={contentQuery} />;
    };

    return (
        <PageLayout
            header="content"
            headerIcon={<ContentIcon fontSize="inherit" />}
            {...(!isEmpty && { headerAction: CreateButton })}
            headerOthers={renderHeaderOthers()}
        >
            {createTemplateStatus === MutationStatus.SUCCESS && (
                <SuccessSnackbar successMessage="Email successfully saved." />
            )}
            {updateTemplateStatus === MutationStatus.SUCCESS && (
                <SuccessSnackbar successMessage="Email successfully updated." />
            )}
            {createLandingPageStatus === MutationStatus.SUCCESS && (
                <SuccessSnackbar successMessage="Landing page successfully saved." />
            )}
            {updateLandingPageStatus === MutationStatus.SUCCESS && (
                <SuccessSnackbar successMessage="Landing page successfully updated." />
            )}
            {renderPageBody()}
        </PageLayout>
    );
};

export default ContentPageLayout;
