import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ContentType } from 'domains/content/types';
import MoreOptionsDropdown from 'domains/core/components/MoreOptionsDropdown';
import useContentQueryManager from 'hooks/useContentQueryParams';
import { extractContentIdFromVal, getContentCreatePath } from './utils';

type Props = {
    isTemplate: boolean;
    toggleDeleteModalOpen: (content: any) => void;
    val: any;
};

const useGetMoreOptionsCell = ({ isTemplate, toggleDeleteModalOpen, val }: Props) => {
    const { contentFolder: hasContentFoldersFeature } = useFlags();

    const history = useHistory();

    const { changeViewParams } = useContentQueryManager();

    let menuItems;
    if (!hasContentFoldersFeature) {
        const editOption = {
            name: 'Edit',
            onClick: () => {
                const path = getContentCreatePath(isTemplate, val.row.values.id);
                history.push(path);
            },
        };
        const copyOption = {
            name: 'Copy',
            onClick: () => {
                const path = getContentCreatePath(isTemplate, val.row.values.id, true);
                history.push(path);
            },
        };
        const deleteOption = {
            name: 'Delete',
            onClick: () => toggleDeleteModalOpen(val.row.original),
        };
        const shouldShowDeleteOption = !isTemplate || val.row.original.canDelete;

        menuItems = shouldShowDeleteOption ? [editOption, copyOption, deleteOption] : [editOption, copyOption];

        return <MoreOptionsDropdown menuItems={menuItems} />;
    }

    const contentId = extractContentIdFromVal(val);

    const renameOption = { name: 'Rename', onClick: () => {} };
    const moveOption = { name: 'Move', onClick: () => {} };
    const deleteOption = { name: 'Delete', onClick: () => {} };

    if (val.row.original.entityType === ContentType.FOLDER) {
        const openOption = {
            name: 'Open',
            onClick: () => changeViewParams({ newFolderId: contentId }),
        };

        menuItems = [openOption, renameOption, deleteOption];
    } else {
        const editOption = {
            name: 'Edit',
            onClick: () => {
                const path = getContentCreatePath(isTemplate, contentId);
                history.push(path);
            },
        };
        const copyOption = {
            name: 'Copy',
            onClick: () => {
                const path = getContentCreatePath(isTemplate, contentId, true);
                history.push(path);
            },
        };

        menuItems = [editOption, copyOption, renameOption, moveOption, deleteOption];
    }

    return <MoreOptionsDropdown menuItems={menuItems} />;
};

export default useGetMoreOptionsCell;
