import { Close, InfoOutlined } from '@mui/icons-material';
import { Autocomplete, Chip } from '@mui/material';
import { greyChipStyles } from 'domains/core/components/Chip/Chip';
import InputLabel from 'domains/core/components/InputLabel';
import TextField from 'domains/core/components/TextField';
import { ValueFilterReturnData } from '../../ValueOverviewDashboard/Filters/useGetFilterData';
import { CampaignForFilter, SendForFilter } from '../types';

type AutocompleteObject = {
    label: string;
    id: string;
};

const getAutocompleteObjects = (
    datums: SendForFilter[] | CampaignForFilter[] | ValueFilterReturnData['specialties']
): AutocompleteObject[] =>
    datums?.map((datum: any) => {
        if (typeof datum === 'string') {
            return {
                label: datum,
                id: datum,
            };
        }
        if (!!datum?.send_name) {
            return {
                label: datum.send_name,
                id: datum.send_id,
            };
        }
        return {
            label: datum?.campaign_name,
            id: datum?.campaign_id,
        };
    });

type InputProps = {
    disabled?: boolean;
    label: string;
    labelTooltip?: string;
    onChange?: (value: any) => void;
    options?: SendForFilter[] | CampaignForFilter[] | string[];
    selectedValues: any[];
};

const FilterAutocompleteInput = ({ options, disabled, label, labelTooltip, onChange, selectedValues }: InputProps) => {
    const autocompleteOptions = getAutocompleteObjects(options);
    const autocompleteValues = getAutocompleteObjects(selectedValues);

    return (
        <Autocomplete
            disabled={disabled}
            getOptionLabel={(option: any) => option?.label}
            id={label}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            limitTags={2}
            multiple
            onChange={(event: any, newValue: any) => onChange(newValue)}
            options={autocompleteOptions ?? []}
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={
                        <InputLabel
                            label={label}
                            labelIcon={<InfoOutlined color={disabled ? 'disabled' : 'inherit'} fontSize="inherit" />}
                            labelIconTooltipText={labelTooltip}
                        />
                    }
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        deleteIcon={<Close />}
                        label={option.label}
                        {...getTagProps({ index })}
                        sx={{ ...greyChipStyles }}
                    />
                ))
            }
            sx={{ flex: '1' }}
            value={autocompleteValues ?? []}
        />
    );
};

export default FilterAutocompleteInput;
