export type BeeConfig = {
    client_uid: string;
    mergeTags: MergeTag[];
    contentDefaults: any;
    editorFonts: any;
    autosave: number | boolean;
    defaultForm?: any;
};

export type MergeTag = {
    name: string;
    value: string;
    previewValue: string;
};

export type SenderProfile = {
    id: number;
    name: string;
    from_address: string;
    reply_address: string;
};

export enum ContentType {
    ALL = 'all',
    FOLDER = 'folder',
    EMAIL = 'email',
    PAGE = 'page',
}

export type Template = {
    id: number;
    name: string;
    html: string;
    createdTimestamp: string;
    lastModifiedTimestamp: string;
    isLayout: boolean;
    beeJson?: string;
    preHeader?: string;
    senderProfileId?: number;
    subjectLine?: string;
    // This is only used by the type guard
    templateFields?: any;
    // This is neccessary as we are passing Template | LandingPage | Content as the type to the
    // ContentTable component. We have a new key 'type' on the content API and hence this needs to
    // be added as optional to both Template and LandingPage types.
    type?: string;
};

export type LandingPage = {
    id: number;
    name: string;
    beeJson?: string;
    createdTimestamp: string;
    lastModifiedTimestamp: string;
    deletedTimestamp?: string;
    type?: string;
};

export type CreateLandingPageInput = Pick<LandingPage, 'beeJson'> & Partial<Pick<LandingPage, 'name'>>;
export type CreateTemplateInput = Pick<Template, 'beeJson'> & { templateName?: string };

export type UpdateLandingPageInput = Pick<LandingPage, 'beeJson'> & Partial<Pick<LandingPage, 'name'>> & { id: string };
export type UpdateTemplateInput = Pick<Template, 'beeJson'> & {
    id: string;
    preHeader?: string;
    senderProfileId?: number;
    subjectLine?: string;
    templateName?: string;
};

export function isTemplate(obj: string | Template | LandingPage | Content): obj is Template {
    return typeof obj !== 'undefined' && typeof obj !== 'string' && 'beeJson' in obj && 'templateFields' in obj;
}
export function isLandingPage(obj: string | Template | LandingPage | Content): obj is LandingPage {
    return typeof obj !== 'undefined' && typeof obj !== 'string' && 'beeJson' in obj && !('templateFields' in obj);
}

export type Content = {
    id: number;
    name: string;
    createdTimestamp: string;
    lastModifiedTimestamp: string;
    type: string;
    canDelete: boolean;
};

export enum ContentTypeEnumForBackend {
    'Email' = 'email',
    'Landing Page' = 'landing_page',
    'Folder' = 'folder',
    'Default' = '',
}

export enum ContentTypeForComparison {
    FOLDER = 'Folder',
    LANDING_PAGE = 'Landing Page',
    EMAIL = 'Email',
    DEFAULT = 'Default',
}
