import { LineProps, ResponsiveLine } from '@nivo/line';
import { graphColors } from 'colors';
import CustomTooltip from '../CustomTooltip';
import NoDataMessage from '../NoDataMessage';

export type DataPoint = {
    x: string;
    y: string;
    tooltipLabel?: string;
};

export type LineGraphData = {
    id: string;
    data: DataPoint[];
};

type Props = {
    bottomLegendLabel?: string;
    data: LineGraphData[];
    enableArea?: boolean;
    hideBottomAxis?: boolean;
    leftLegendLabel?: string;
    legends?: LineProps['legends'];
    renderCustomTooltip?: (point: any) => any;
    tickValues?: string;
    tooltipLabel?: string;
    scale?: string;
};

const LineGraph = ({
    bottomLegendLabel,
    data,
    enableArea,
    hideBottomAxis,
    leftLegendLabel,
    legends,
    renderCustomTooltip,
    tickValues,
    tooltipLabel,
    scale,
}: Props) => {
    if (!data || !data[0]?.data?.length) return <NoDataMessage />;

    const dataWithColors = data.map((datum, index) => ({
        ...datum,
        color: graphColors[index],
    }));

    const renderTooltip = (point: any) => {
        if (renderCustomTooltip) return renderCustomTooltip(point);

        return (
            <CustomTooltip
                color={point.serieColor}
                label={point.data.xFormatted}
                value={`${tooltipLabel ?? leftLegendLabel} - ${point.data.yFormatted}`}
            />
        );
    };

    const isLinearScale = scale === 'linear';
    const isPercentageScale = scale === 'percentage';

    const getAxisBottom = (): LineProps['axisBottom'] => {
        if (hideBottomAxis) return null;

        const sharedProps = {
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: bottomLegendLabel,
            legendOffset: 36,
            legendPosition: 'middle' as LineProps['axisBottom']['legendPosition'],
            truncateTickAt: 0,
        };

        if (isLinearScale || isPercentageScale) return sharedProps;

        return {
            ...sharedProps,
            tickValues: tickValues ?? 'monthly',
            format: "%b '%y",
        };
    };

    const getBottomMargin = () => {
        if (legends && bottomLegendLabel) return 80;
        if (bottomLegendLabel) return 60;
        if (hideBottomAxis) return 30;
        return 40;
    };
    const bottomMargin = getBottomMargin();

    const xFormat: LineProps['xFormat'] = isPercentageScale || isLinearScale ? null : 'time:%m/%d/%Y';

    const xScale: LineProps['xScale'] =
        isPercentageScale || isLinearScale
            ? { type: 'linear', min: 'auto' }
            : {
                  format: '%Y-%m-%d',
                  precision: 'day',
                  type: 'time',
                  useUTC: false,
              };

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <ResponsiveLine
                axisBottom={getAxisBottom()}
                axisLeft={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: leftLegendLabel,
                    legendOffset: -60,
                    legendPosition: 'middle',
                    truncateTickAt: 0,
                    format: (value) => (isPercentageScale ? `${value * 100}%` : value.toLocaleString()),
                }}
                colors={{ datum: 'color' }}
                data={dataWithColors}
                enableArea={enableArea}
                enableGridX={false}
                legends={legends}
                margin={{
                    top: 20,
                    right: 50,
                    bottom: bottomMargin,
                    left: 80,
                }}
                pointBorderColor={{ from: 'serieColor' }}
                pointBorderWidth={5}
                pointColor={{ theme: 'background' }}
                pointSize={2}
                pointLabel="data.yFormatted"
                pointLabelYOffset={-12}
                tooltip={({ point }) => renderTooltip(point)}
                useMesh={true}
                xFormat={xFormat}
                xScale={xScale}
                yFormat={isPercentageScale ? '~%' : ' >-,.2~f'}
                yScale={{
                    type: 'linear',
                    min: isPercentageScale ? 0 : 'auto',
                    max: isPercentageScale ? 1 : 'auto',
                }}
            />
        </div>
    );
};

export default LineGraph;
