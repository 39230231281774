import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import {
    SegmentFilter,
    SegmentFilterEventCondition,
    SegmentFilterEventFields,
    SegmentFilterState,
} from 'domains/segments/types';
import Chip from 'domains/core/components/Chip';
import DeleteButton from 'domains/core/components/DeleteButton';
import FilterInput from './FilterInput';
import OperatorInput from './OperatorInput';
import ValueInput from './ValueInput';
import { NullOperator } from 'domains/segments/types';

const fullWidth = { width: '15rem' };
const chipContainerStyles = {
    alignSelf: 'flex-start',
    display: 'inline-block',
    flex: 'none',
    paddingLeft: '8px',
    paddingTop: '32px',
    textAlign: 'right' as const,
    width: '60px',
};
const chipStyles = {
    borderRadius: '4px',
    fontSize: '20px',
    fontWeight: '400',
    height: '30px',
    padding: '2px 11px 4px',
};
const deleteButtonStyles = {
    marginX: '-8px',
    paddingTop: '14px',
};
const inputsStyles = {
    borderRadius: '6px',
    columnGap: '16px',
    marginLeft: '8px',
    paddingLeft: '8px',
    paddingRight: '16px',
    paddingY: '24px',
    rowGap: '16px',
};

type Props = {
    condition: SegmentFilterEventCondition;
    conditionIndex: number;
    filterOptions: SegmentFilter[];
    filterState: SegmentFilterState<SegmentFilterEventFields>;
    filterIndex: number;
    handleDatetimeError: (filterId: string, error: boolean) => void;
    isResponsive?: boolean;
    updateSegmentFilter: (
        index: number,
        filter: SegmentFilterState<SegmentFilterEventFields>,
        resetPreview?: boolean
    ) => void;
};

const SubFilter = ({
    condition,
    conditionIndex,
    filterOptions,
    filterState,
    filterIndex,
    handleDatetimeError,
    isResponsive,
    updateSegmentFilter,
}: Props) => {
    const { name, operator } = condition;

    const availableFilterOptions = useMemo(() => {
        // This returns an array of the names of the subFilters in use _except_ for the currently active subFilter
        const activeFilterOptionNames = filterState.categorySpecificFields?.conditions
            ?.filter((condition) => condition.name !== name)
            .map((option) => option.name);

        return filterOptions.filter((filter) => !activeFilterOptionNames.includes(filter.name));
    }, [filterState, filterOptions, name]);

    const [isHighlighted, setIsHighlighted] = useState(false);

    const subFilterSx = {
        ...(isResponsive && { width: '24rem' }),
    };
    const inputsHighlightedStyles = {
        ...inputsStyles,
        ...(isHighlighted && { backgroundColor: 'action.hover' }),
        ...(isResponsive && name && { flexWrap: 'wrap' }),
        ...(!isResponsive && { marginLeft: '8px', paddingRight: '16px' }),
    };

    const showValueInput = operator && operator !== NullOperator.IS_NOT_NULL && operator !== NullOperator.IS_NULL;

    const handleRemoveSubFilter = (index: number) => {
        const newConditions = filterState.categorySpecificFields.conditions.filter(
            (_, conditionIndex) => conditionIndex !== index
        );

        updateSegmentFilter(filterIndex, {
            ...filterState,
            categorySpecificFields: {
                ...filterState.categorySpecificFields,
                conditions: newConditions,
            },
        });
    };

    const renderChip = () => {
        const isFirstSubFilter = conditionIndex === 0;

        return (
            <div style={chipContainerStyles}>
                <Chip label={isFirstSubFilter ? 'with' : 'and'} sx={chipStyles} />
            </div>
        );
    };

    return (
        <Stack direction="row" sx={subFilterSx}>
            {renderChip()}
            <Stack direction="row" sx={inputsHighlightedStyles}>
                <FilterInput
                    condition={condition}
                    conditionIndex={conditionIndex}
                    filterIndex={filterIndex}
                    filterOptions={availableFilterOptions}
                    filterState={filterState}
                    updateSegmentFilter={updateSegmentFilter}
                    {...(isResponsive && { sx: fullWidth })}
                />
                {name && (
                    <OperatorInput
                        condition={condition}
                        conditionIndex={conditionIndex}
                        filterIndex={filterIndex}
                        filterOptions={availableFilterOptions}
                        filterState={filterState}
                        updateSegmentFilter={updateSegmentFilter}
                        {...(isResponsive && { sx: fullWidth })}
                    />
                )}
                {showValueInput && (
                    <ValueInput
                        conditionIndex={conditionIndex}
                        filterIndex={filterIndex}
                        filterOptions={availableFilterOptions}
                        filterState={filterState}
                        handleDatetimeError={handleDatetimeError}
                        isResponsive={isResponsive}
                        updateSegmentFilter={updateSegmentFilter}
                    />
                )}
                <Stack sx={deleteButtonStyles}>
                    <DeleteButton
                        tooltipTitle="Delete Sub-filter"
                        onClick={() => handleRemoveSubFilter(conditionIndex)}
                        onMouseLeave={() => setIsHighlighted(false)}
                        onMouseOver={() => setIsHighlighted(true)}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default SubFilter;
